import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Form } from 'antd';
import { isEmpty } from 'lodash';

import {
  companyProperty,
  docTypes,
  FILE_DONE,
  genderOptions,
  maxLengthDocTypes,
  REPRESENTATIVE,
  representativeProperty,
  validationDocTypes,
  validationType,
} from '../../utilities/Constant';
import { requestProperty } from '../../templates/NewRequest/constants';
import { generateFileUrl, getValueDefault, validateEmail } from '../../utilities/Functions';
import { KB_1 } from '../DropZone/DropZone';

import WSelectInput from '../SelectInput/index';
import CustomIcon from '../CustomIcon/CustomIcon';
import HeaderCompany from '../HeaderCompany';
import WUploadFile from '../UploadFile';
import PTextInput from '../PTextInput';

import './index.less';

const { Title, Text } = Typography;
// TODO example data, change to real data

const WNaturalRepresentativeCard = ({
  title = 'Información del representante',
  personTypeIndex,
  onFormStateChange,
  counterPart,
  selectedIndexCompany = '',
  representatives = [],
  selectedRepresentative = '',
  onSelectRepresentative = () => {},
  addRepresentative = () => {},
  selectedIndexRepresentative,
  currentRepresentative,
  deleteRepresentative,
  errorMessages,
}) => {
  const naturalPerson = personTypeIndex === 1;
  const [fileList, setFileList] = useState(null);
  const errorUpload = !naturalPerson && errorMessages && !fileList?.length > 0;
  const {
    NAME,
    MIDDLE_NAME,
    FAMILY_NAME,
    GENDER,
    DOCUMENT_TYPE,
    DOCUMENT_NUMBER,
    RUC,
    ADDRESS,
    EMAIL,
    POWER_VIGENCY,
  } = representativeProperty;

  const checkEmail = () => {
    const currentEmail = currentRepresentative[EMAIL];
    return validateEmail(currentEmail);
  };
  const RULE_FIELDS = {
    RULE_NAME: [{ required: true, message: 'Ingrese nombres' }],
    RULE_MIDDLE_NAME: [{ required: true, message: 'Ingrese apellido paterno' }],
    RULE_FAMILY_NAME: [{ required: true, message: 'Ingrese apellido materno' }],
    RULE_GENDER: [{ required: true, message: 'Seleccione género' }],
    RULE_DOCUMENT_TYPE: [{ required: true, message: 'Seleccione tipo de documento' }],
    RULE_DOCUMENT_NUMBER: [{ required: true, message: 'Ingrese número de documento' }],
    RULE_RUC: [{ required: true, message: 'Ingrese RUC' }],
    RULE_ADDRESS: [{ required: true, message: 'Ingrese dirección' }],
    RULE_EMAIL: [{ required: true, validator: checkEmail }],
  };
  const {
    RULE_NAME,
    RULE_MIDDLE_NAME,
    RULE_FAMILY_NAME,
    RULE_GENDER,
    RULE_DOCUMENT_TYPE,
    RULE_DOCUMENT_NUMBER,
    RULE_RUC,
    RULE_ADDRESS,
    RULE_EMAIL,
  } = RULE_FIELDS;

  const handleRepresentative = (value, property) => {
    const counterPartToUpdate = [...counterPart];
    // legal person
    if (personTypeIndex === 0) {
      counterPartToUpdate[personTypeIndex].companies[selectedIndexCompany][
        companyProperty.LEGAL_REPRESENTATIVES
      ][selectedIndexRepresentative][property] = value;
      if (isEmpty(value)) {
        delete counterPartToUpdate[personTypeIndex].companies[selectedIndexCompany][
          companyProperty.LEGAL_REPRESENTATIVES
        ][selectedIndexRepresentative][property];
      }
    } else {
      // natural person
      counterPartToUpdate[personTypeIndex][companyProperty.LEGAL_REPRESENTATIVES][
        selectedIndexRepresentative
      ][property] = value;
      if (isEmpty(value)) {
        delete counterPartToUpdate[personTypeIndex][companyProperty.LEGAL_REPRESENTATIVES][
          selectedIndexRepresentative
        ][property];
      }
    }
    onFormStateChange(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const documentType = currentRepresentative[DOCUMENT_TYPE];

  const handleUpload = async (option) => {
    try {
      const { onSuccess, file } = option;
      const { filename, id, url } = await generateFileUrl(file);
      const value = {
        url,
        filename,
        size: Math.round(file.size / KB_1),
        type: file.type,
        id,
      };
      handleRepresentative(value, POWER_VIGENCY);
      onSuccess();
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((f) => {
      const file = { ...f };
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (newFileList.length > 0) {
      setFileList(newFileList);
    } else {
      setFileList(null);
      handleRepresentative(null, POWER_VIGENCY);
    }
  };
  const idFieldItem = (propItem) =>
    `${selectedIndexCompany}${propItem}${currentRepresentative.representative}${personTypeIndex}`;

  useEffect(() => {
    const currentFile = currentRepresentative[POWER_VIGENCY];
    if (currentRepresentative && currentFile) {
      setFileList([
        { ...currentFile, status: FILE_DONE, percent: 100, name: currentFile.filename },
      ]);
    } else {
      setFileList(null);
    }
  }, [currentRepresentative]);

  return (
    <div className="naturalRepresentative-container">
      <Row className="headerCard-row">
        <Col flex="0 1 325px">
          <div className="firstRepresentative-titleContainer">
            <Title className="firstRepresentative-title">{title}</Title>
          </div>
          <Text className="d-flex gap-8 ">
            <CustomIcon className="firstRepresentative-subtitleIcon" type="ic_alert_withoutBackg" />
            Es obligatorio completar todo los campos.
          </Text>
        </Col>
        {personTypeIndex === 0 && (
          <div className="header-company-container">
            <HeaderCompany
              options={representatives}
              onSelect={onSelectRepresentative}
              selectedOption={selectedRepresentative}
              onClick={addRepresentative}
              buttonText="Crear representante"
            />
            {selectedRepresentative !== `${REPRESENTATIVE} 1` && (
              <CustomIcon
                className="firstRepresentativeCard-delete"
                onClick={deleteRepresentative}
                type="deleteRepresentative"
              />
            )}
          </div>
        )}
      </Row>
      <Row className="representativeCard-row">
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(NAME)} rules={RULE_NAME}>
            <PTextInput
              placeholder="Nombres"
              value={getValueDefault(currentRepresentative[NAME])}
              onChange={(value) => handleRepresentative(value, NAME)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(MIDDLE_NAME)} rules={RULE_MIDDLE_NAME}>
            <PTextInput
              placeholder="Apellido Paterno"
              value={getValueDefault(currentRepresentative[MIDDLE_NAME])}
              onChange={(value) => handleRepresentative(value, MIDDLE_NAME)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(FAMILY_NAME)} rules={RULE_FAMILY_NAME}>
            <PTextInput
              placeholder="Apellido Materno"
              value={getValueDefault(currentRepresentative[FAMILY_NAME])}
              onChange={(value) => handleRepresentative(value, FAMILY_NAME)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(GENDER)} rules={RULE_GENDER}>
            <WSelectInput
              options={genderOptions}
              placeholder="Género"
              value={currentRepresentative[GENDER]}
              onChange={(value) => handleRepresentative(value, GENDER)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(DOCUMENT_TYPE)} rules={RULE_DOCUMENT_TYPE}>
            <WSelectInput
              options={docTypes}
              placeholder="Tipo de Doc"
              value={documentType}
              onChange={(value) => handleRepresentative(value, DOCUMENT_TYPE)}
            />
          </Form.Item>
        </Col>
        <Col flex="1 1 180px">
          <Form.Item name={idFieldItem(DOCUMENT_NUMBER)} rules={RULE_DOCUMENT_NUMBER}>
            <PTextInput
              placeholder="Número de documento"
              value={getValueDefault(currentRepresentative[DOCUMENT_NUMBER])}
              onChange={(value) => handleRepresentative(value, DOCUMENT_NUMBER)}
              type="number"
              maxLength={
                isEmpty(getValueDefault(documentType)) ? 11 : maxLengthDocTypes[documentType]
              }
              validation={
                isEmpty(documentType)
                  ? validationType.RUC_NATURAL
                  : validationDocTypes[documentType]
              }
              errorMessage={`${
                errorMessages && getValueDefault(currentRepresentative[DOCUMENT_NUMBER])
                  ? `${documentType} inválido`
                  : ''
              }`}
              disabled={!documentType}
            />
          </Form.Item>
        </Col>
        {naturalPerson && (
          <Col flex="1 1 180px">
            <Form.Item name={idFieldItem(RUC)} rules={RULE_RUC}>
              <PTextInput
                placeholder="RUC"
                value={getValueDefault(currentRepresentative[RUC])}
                onChange={(value) => handleRepresentative(value, RUC)}
                type="number"
                maxLength={11}
                validation={validationType.RUC_NATURAL}
                errorMessage={`${
                  errorMessages && getValueDefault(currentRepresentative[RUC])
                    ? 'RUC natural inválido'
                    : ''
                }`}
              />
            </Form.Item>
          </Col>
        )}
        {naturalPerson && (
          <>
            <Col flex="1 1 180px">
              <Form.Item name={idFieldItem(ADDRESS)} rules={RULE_ADDRESS}>
                <PTextInput
                  placeholder="Dirección"
                  value={getValueDefault(currentRepresentative[ADDRESS])}
                  onChange={(value) => handleRepresentative(value, ADDRESS)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 1 180px">
              <Form.Item name={idFieldItem(EMAIL)} rules={RULE_EMAIL}>
                <PTextInput
                  placeholder="Correo de contacto"
                  value={getValueDefault(currentRepresentative[EMAIL])}
                  onChange={(value) => handleRepresentative(value, EMAIL)}
                  lowerCase
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col flex="1 1 380px" className="two-columns">
          <WUploadFile
            title="Vigencia de Poder"
            fileList={fileList}
            handleUpload={handleUpload}
            handleChange={handleChange}
            errorMessage={errorUpload && 'Debe seleccionar un archivo'}
          />
        </Col>
      </Row>
    </div>
  );
};

export default WNaturalRepresentativeCard;
