import React, { useEffect, useState } from 'react';
import { Layout, Typography, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import WSideBar from '../../components/Sidebar';
import WDrawer from '../../components/Drawer/index';
import { FIELD_NEXTTIME_LOGIN, pathServer } from '../../utilities/Constant';
import SidebarUser from '../../components/SidebarUser';
import { Routes } from '../../configuration/routes/Routes';
import { useAws } from '../../context/aws/awsProvider';
import { logout } from '../../redux/ducks/user';

import './index.less';
import InformativeModal from '../../components/InformativeModal';

const { Header, Content } = Layout;
const { Text } = Typography;

const SESSION_TIME = 60;

const withDashboard = (Component, itemSelected) => (props) => {
  const { isApplicant, isLegalOwner, isAdmin, name, fatherLastName, cargo, avatar } = useSelector(
    (state) => state.user
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  // const isNotUser = [ROL_CODE.legalOwner, ROL_CODE.admin].includes(rol[0]);
  // isNotUser = isLegalOwner || isAdmin
  const { logoutFederation } = useAws();
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onLogout = async () => {
    await logoutFederation();
    dispatch(logout());
  };
  const redirectToLogin = () => {
    setTimeout(() => {
      setShowSessionExpired(false);
      history.push(Routes.LOGIN);
      dispatch(logout());
      window.location.reload();
    }, 1500);
  };

  const loadModal = () => {
    const actualDate = new Date();
    const loginDate =
      localStorage.getItem(FIELD_NEXTTIME_LOGIN) || moment().startOf('day').fromNow();
    if (loginDate) {
      const duration = moment.duration(moment(actualDate).diff(loginDate)).asMinutes();

      if (duration >= SESSION_TIME) {
        setShowSessionExpired(true);
      }
    }
  };

  useEffect(() => {
    loadModal();
    const interval = setInterval(loadModal, 60000);
    if (showSessionExpired) {
      redirectToLogin();
      clearInterval(interval);
    }
  }, [showSessionExpired]);

  return (
    <Layout style={{ minHeight: '100vh' }} theme="light" className="container-main">
      <WDrawer collapsed={collapsed} onChange={onCollapse} />
      {isApplicant && (
        <WSideBar collapsed={collapsed} onLogout={onLogout} selected={itemSelected} />
      )}
      {(isLegalOwner || isAdmin) && (
        <div className="container-menuSidebar">
          <div className="container-logoSidebar">
            <ReactSVG src={`${pathServer.PATH_ICONS}ic_logoSidebar.svg`} />
          </div>
          <SidebarUser isAdmin={isAdmin} selected={itemSelected} />
        </div>
      )}

      <Layout className="site-layout">
        {showSessionExpired && (
          <InformativeModal
            icon="session_expired"
            visible={showSessionExpired}
            title="Sesión Expirada"
            subtitle="Por seguridad , tu sesión ha expirado ,vuelve a iniciar sesión."
          />
        )}
        {(isLegalOwner || isAdmin) && (
          <Header className="header headerUser">
            <div aling="rigth" className="user-container user-containerUser">
              <div style={{ display: 'grid', lineHeight: '20px' }}>
                <Text className="text-user">
                  {name} {fatherLastName}
                </Text>
                <Text className="text-user-profile">{cargo}</Text>
              </div>
              {isEmpty(avatar) ? (
                <ReactSVG className="icon user-icon" src={`${pathServer.PATH_ICONS}ic_user.svg`} />
              ) : (
                <img className="user-avatar" src={avatar} alt="avatar" />
              )}
              <Divider type="vertical" className="divider-user" />
              <ReactSVG
                onClick={() => onLogout()}
                className="icon"
                src={`${pathServer.PATH_ICONS}ic_logoutSidebar.svg`}
              />
            </div>
          </Header>
        )}
        {isApplicant && (
          <Header align="right" className="site-layout-background header">
            <div className="user-container">
              <div className="container-user">
                <Divider type="vertical" className="divider-user" />
                {isEmpty(avatar) ? (
                  <ReactSVG
                    className="icon user-icon"
                    src={`${pathServer.PATH_ICONS}ic_user.svg`}
                  />
                ) : (
                  <img className="user-avatar" src={avatar} alt="avatar" />
                )}
                <div style={{ display: 'grid', lineHeight: '20px' }}>
                  <Text className="text-user">
                    {name} {fatherLastName}
                  </Text>
                  <Text style={{ fontSize: '10px', color: '#2B0D6180' }}>{cargo}</Text>
                </div>
              </div>
            </div>
          </Header>
        )}
        <Content style={{ margin: '0 16px' }}>
          <div
            id="siteLayoutBackground"
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360, background: 'white' }}
          >
            <Component
              isNotUser={isLegalOwner || isAdmin}
              name={name}
              fatherLastName={fatherLastName}
              {...props}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

withDashboard.propTypes = {};

export default withDashboard;
