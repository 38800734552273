import React, { useState, useRef } from 'react';
import { Modal, Input, Divider, Spin, Typography, Button } from 'antd';
import { isEmpty } from 'lodash';

import { generateFileUrl } from '../../utilities/Functions';
import { KB_1 } from '../DropZone/DropZone';
import { FileItem } from '../../templates/NewRequest/ThirdStepRequest';
import { ContainerCard, DropZone } from '../index';

import './index.less';

const { TextArea } = Input;
const { Text } = Typography;

const INITIAL_MODEL_OBSERVATION = {
  type: 1,
  justification: '',
  file: {},
};

const fields = ['justification', 'file'];

const Error = ({ visible, text = 'Error en esta sección.' }) => {
  return <>{visible && <Text style={{ color: '#ff6565', marginTop: '5px' }}>{text}</Text>}</>;
};

const ModelObservationModal = ({ visible, sendObservation, cancel }) => {
  const [modelObservation, setModelObservation] = useState({ ...INITIAL_MODEL_OBSERVATION });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const textArea = useRef(null);
  const { justification, file, type } = modelObservation;
  const { filename } = file;

  const onSaveObs = (value, prop) => {
    modelObservation[prop] = value;
    setModelObservation({ ...modelObservation });
    setErrors({ ...errors, [prop]: false });
  };

  const cancelModal = () => {
    setModelObservation({ ...INITIAL_MODEL_OBSERVATION });
    setErrors({});
    cancel();
  };

  const onSelectFile = async (uploadedFile) => {
    setIsLoadingUpload(true);
    const { url, id } = await generateFileUrl(uploadedFile);
    setIsLoadingUpload(false);
    if (!isEmpty(url)) {
      const fileToAdd = {
        url,
        id,
        filename: uploadedFile.name,
        size: Math.round(uploadedFile.size / KB_1),
        type: uploadedFile.type,
      };
      onSaveObs(fileToAdd, 'file');
    }
  };

  const send = async () => {
    const errorsFound = validateFields();
    if (errorsFound.justification || errorsFound.file) {
      setErrors({ ...errorsFound });
    } else {
      setIsLoading(true);
      if (sendObservation) await sendObservation({ type, justification, file });
      setIsLoading(false);
      cancel();
      setModelObservation({ ...INITIAL_MODEL_OBSERVATION });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    fields.forEach((field) => {
      if (isEmpty(modelObservation[field])) {
        newErrors[field] = true;
        if (field === 'justification') {
          textArea.current.focus();
        }
      }
    });
    return newErrors;
  };

  return (
    <Modal visible={visible} footer={null} className="model-observation-modal">
      <Spin tip="Cargando información..." spinning={isLoading}>
        <ContainerCard className="model-observation-body">
          <div className="container-description">
            {!isEmpty(file) ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text className="justification-title">Observación de modelo</Text>
                <div className="file-container">
                  <FileItem
                    key={`file-${filename}`}
                    attached={file}
                    onDelete={() => onSaveObs({}, 'file')}
                  />
                </div>
              </div>
            ) : (
              <Spin tip="Subiendo archivo..." spinning={isLoadingUpload}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <DropZone
                    title="Observación de modelo"
                    className="secondStep-justificationDropzone"
                    button="Aceptar"
                    onSelectFile={onSelectFile}
                    errorMessage={errors.file && 'Debe seleccionar un archivo'}
                  />
                </div>
              </Spin>
            )}
            <Divider type="vertical" className="divider-model-observation" />
            <div className="container-text-area">
              <TextArea
                style={errors.justification ? { border: '1px solid #ff6565' } : {}}
                ref={textArea}
                className="secondStep-justificationInput"
                placeholder="Ingresar observación"
                onChange={(e) => {
                  onSaveObs(e.target.value, 'justification');
                }}
                value={justification}
                autoSize={{ minRows: 8, maxRows: 8 }}
              />
              <Error visible={errors.justification} text="La observación es requerida." />
            </div>
          </div>
          <div className="container-send-button">
            <Button type="primary" className="button-cancel" loading={false} onClick={cancelModal}>
              Cancelar
            </Button>
            <Button
              type="primary"
              className="button-send-observation"
              loading={false}
              onClick={send}
            >
              Enviar
            </Button>
          </div>
        </ContainerCard>
      </Spin>
    </Modal>
  );
};

ModelObservationModal.defaultOptions = {};

export default ModelObservationModal;
