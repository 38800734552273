import moment from 'moment'; // https://momentjs.com/
import { isEmpty } from 'lodash';

import {
  Icons,
  REPRESENTATIVE,
  contractModelType,
  ROL_CODE,
  ROLE_LEGAL_OWNER,
  ROLE_APPLICANT,
  ROLE_ADMINISTRATOR,
} from './Constant';
import FileServices from '../../domain/usecases/FileServices';
import { requestProperty } from '../templates/NewRequest/constants';
import { EMAIL_REGEX } from './Validation';

export const validateAttachement = (attached) =>
  attached.some((at) => at?.required && at?.files?.length === 0);

export const hasTrueValue = (arr) => arr.includes(true);

export const filterObsByAttribute = (observations, customAttribute, attribute) =>
  observations.filter((observation) => observation[customAttribute] === attribute);

export const checkValues = (values) =>
  values.map((value) => !value || Object.keys(value).length === 0);

export function getStringDateWithFormat(stringDate) {
  if (stringDate) return moment(stringDate, 'DD/MM/YYYY').format('MM/DD/YYYY');
  return null;
}

export function getDateTimestamp(stringDate) {
  let dateTimestamp = new Date(getStringDateWithFormat(stringDate));
  dateTimestamp = dateTimestamp.getTime() / 1000;
  return dateTimestamp;
}

export function getValuesObservation(observations, nrtype) {
  const observation = filterObsByAttribute(observations, 'type', nrtype);
  const values = [observation[0]?.file, observation[0]?.justification];
  const result = checkValues(values);
  return result;
}

export function getIcon(type) {
  if (Icons[type] !== undefined) {
    return Icons[type].url;
  }
  return Icons.default.url;
}

export function convertTimeStampToDate(timestamp) {
  if (timestamp && timestamp !== 0) {
    // eslint-disable-next-line new-cap
    return new moment.unix(timestamp);
  }
  return null;
}
export function cutChainString(str, length) {
  if (str.toString) {
    return `${str.toString().substring(0, length)}...`;
  }
  return null;
}

export function formatDate(date, format = 'L') {
  if (date) {
    return moment(date).format(format);
  }
  return null;
}

export function getValueDefault(value) {
  if (value !== null && value !== undefined) {
    return value;
  }
  return '';
}

export async function generateFileUrl(file) {
  try {
    let url = '';
    const [presingUrlData, errorPresingUrl] = await FileServices.getPresingUrl(file.name);
    if (presingUrlData && errorPresingUrl === null) {
      const { id, presingedUrl } = presingUrlData[0];
      const [uploadFileData, errorUploadFile] = await FileServices.uploadFile(
        presingedUrl,
        file,
        file.type
      );
      if (uploadFileData && errorUploadFile === null) {
        const [data, error] = await FileServices.downloadFile(id);
        if (data && error === null) {
          const { presingedUrl: Url } = data[0];
          url = Url;
        }
      }
    }
    return { url, ...presingUrlData[0] };
  } catch (e) {
    console.log(e);
    return '';
  }
}
export async function downloadFileUrl({ id }) {
  const [data] = await FileServices.downloadFile(id);
  if (data) window.open(data[0].presingedUrl);
}
export function getRepresentatives(legalRepresentatives) {
  return legalRepresentatives.map((re, index) => {
    return `${REPRESENTATIVE} ${index + 1}`;
  });
}

export function getRolById(rolId) {
  const roleMap = {
    [ROL_CODE.legalOwner]: ROLE_LEGAL_OWNER,
    [ROL_CODE.applicant]: ROLE_APPLICANT,
    [ROL_CODE.admin]: ROLE_ADMINISTRATOR,
  };

  return roleMap[rolId];
}
export async function onSelectContractFile(file, imageUrl, data) {
  try {
    const KB_1 = 1000;
    const { origin, contractModel, onSetRequest } = data;
    const contractModelToUpdate = { ...contractModel };
    let url = imageUrl;
    let id;
    if (origin === contractModelType.EXTERNAL) {
      const { url: urlObtained, id: idObtained } = await generateFileUrl(file);
      url = urlObtained;
      id = idObtained;
    }
    if (!isEmpty(url)) {
      contractModelToUpdate.justify = {
        ...contractModelToUpdate.justify,
        id,
        url,
        modelName: file.name,
        size: Math.round(file.size / KB_1),
        type: file.type,
      };
      onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function onSelectJustifyFile(file, contractModel, onSetRequest) {
  console.log('🚀 contractModel:', contractModel);
  const KB_1 = 1000;
  const { url, id } = await generateFileUrl(file);
  if (!isEmpty(url)) {
    const contractModelToUpdate = { ...contractModel };

    contractModelToUpdate.justify.documents.push({
      url,
      id,
      filename: file.name,
      size: Math.round(file.size / KB_1),
      type: file.type,
    });
    onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
  }
}
export function openFile(file) {
  if (file?.href) {
    window.open(file?.href, '_blank');
  } else downloadFileUrl(file);
}
export function onSelectPickerModel(params, contractModel, onSetRequest) {
  const { id, name, sizeBytes, mimeType, embedUrl } = params;
  const contractModelToUpdate = {
    ...contractModel,
    contractDrive: {
      id,
      modelName: name,
      size: sizeBytes,
      type: mimeType,
      url: embedUrl,
    },
  };
  onSetRequest(requestProperty.CONTRACT_MODEL, contractModelToUpdate);
}

export const maxRepresentative = (representativesList, element = 'representative') => {
  const itemsList = representativesList.map((e) => parseInt(e[element].replace(/\D/g, ''), 10));
  const itemMax = Math.max(...itemsList);
  return itemMax || 1;
};

export const validateEmail = (email) => {
  const currentEmail = email;
  const isValid = EMAIL_REGEX.test(currentEmail);

  if (isEmpty(currentEmail)) return Promise.reject(new Error('Ingrese correo de contacto'));
  if (!isValid) return Promise.reject(new Error('Formato de correo inválido'));

  return Promise.resolve();
};
