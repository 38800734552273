const cognitodomain = 'pdcc.auth.us-east-1.amazoncognito.com';
const clientId = '782854747525';
const signoutUrl = process.env.REACT_APP_URL;

export const signOutUrl = `https://${cognitodomain}/logout?client_id=${clientId}&logout_uri=${signoutUrl}`;

export const provider = {
  google: 'Google',
  facebook: 'Facebook',
};

export const addTokenCognito = () => {
  const keys = Object.keys(localStorage);
  const tokenKey = keys.find(
    (key) => key.includes('CognitoIdentityServiceProvider') && key.includes('idToken')
  );
  const idToken = localStorage.getItem(tokenKey);
  localStorage.setItem('token', idToken);
  return idToken;
};
