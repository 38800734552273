import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Form } from 'antd';

import { KB_1 } from '../../../components/DropZone/DropZone';
import { fileExtension, fileType } from '../../../utilities/Constant';

import { ContainerCard, RequestDescription, AnnexesFolder } from '../../../components';
import { requestProperty } from '../constants';
import CustomIcon from '../../../components/CustomIcon/CustomIcon';
import { convertTimeStampToDate, generateFileUrl } from '../../../utilities/Functions';

import './index.less';

const { Text } = Typography;

const iconTypeMap = {
  [fileType.PDF]: fileExtension.PDF,
  [fileType.DOC]: fileExtension.DOC,
  [fileType.GDOC]: fileExtension.DOC,
  [fileType.DOCX]: fileExtension.DOC,
  [fileType.XLS]: fileExtension.XLS,
  [fileType.XLSX]: fileExtension.XLS,
  [fileType.JPEG]: fileExtension.JPG,
  [fileType.JPG]: fileExtension.JPG,
};

const fileNameMap = {
  filename: 'filename',
  modelName: 'modelName',
};

export const FileItem = ({ attached, onDelete, fileName = 'filename' }) => {
  const handleDelete = () => {
    onDelete(attached.id);
  };
  return (
    <Row align="middle" className="row-file">
      <Col flex="0 0 20px" className="first-item">
        <CustomIcon className="icon-disclaimer" type={iconTypeMap[attached?.type]} />
      </Col>
      <Col flex="1 1 140px" offset={1} className="second-item">
        <Text className="file-name-label">{attached[fileNameMap[fileName]]}</Text>
      </Col>
      <Col flex="0 0 35px" offset={0} className="third-item">
        <Text>{`${attached.size}KB`}</Text>
      </Col>
      <Col flex="0 0 24px" className="icon-delete-container cursor-pointer">
        <CustomIcon className="icon-delete" type="deleteDoc" onClick={handleDelete} />
      </Col>
    </Row>
  );
};

const ThirdStepRequestTemplate = ({ thirdStepData }) => {
  const { request, onSetRequest, errorMessages } = thirdStepData;
  const { attached } = request;
  const [form] = Form.useForm();
  const { contractDate, expirationDate, description, userArea, contractType } = request;
  const savedData = {
    [requestProperty.CONTRACT_DATE]: convertTimeStampToDate(contractDate),
    [requestProperty.EXPIRATION_DATE]: convertTimeStampToDate(expirationDate),
    [requestProperty.USER_AREA]: userArea,
    [requestProperty.CONTRACT_TYPE]: contractType,
    [requestProperty.DESCRIPTION]: description,
  };
  const [folderSelected, setFolderSelected] = useState(null);

  const onSelectFile = async (option) => {
    try {
      const { onSuccess, file } = option;
      const { id, url } = await generateFileUrl(file);
      const value = {
        url,
        filename: file.name,
        size: Math.round(file.size / KB_1),
        type: file.type,
        id,
      };
      const newAttached = [...attached];
      const indexItem = newAttached.findIndex((a) => a.id === folderSelected);
      const currentFiles = newAttached[indexItem].files;
      newAttached[indexItem].files = [...currentFiles, value];
      onSetRequest(requestProperty.ATTACHED, [...newAttached]);
      onSuccess();
    } catch (e) {
      console.log(e);
    }
  };
  const onDeleteFile = (idItem) => {
    if (idItem) {
      const attachedToUpdate = [...attached];
      const indexItem = attachedToUpdate.findIndex((a) => a.id === folderSelected);
      const currentFiles = attachedToUpdate[indexItem].files?.filter((f) => f.id !== idItem);
      attachedToUpdate[indexItem].files = [...currentFiles];
      onSetRequest(requestProperty.ATTACHED, [...attachedToUpdate]);
    }
  };

  useEffect(() => {
    if (errorMessages) {
      form.submit();
    } else {
      form.resetFields();
    }
  }, [errorMessages]);

  return (
    <>
      <Form name="step-3" autoComplete="off" form={form} initialValues={savedData}>
        <div className="thirdStepRequest-header">
          <h2 className="thirdStepRequest-title">Descripción de solicitud</h2>
          <Text>Ingrese descripción de la solicitud</Text>
        </div>
        <RequestDescription
          className="thirdStepRequest-requestDescription"
          request={request}
          onFormStateChange={onSetRequest}
        />

        <ContainerCard className="thirdStepRequest-containerCard">
          <AnnexesFolder
            title="Archivos"
            disclaimer="Subir archivos con forma DOC, PDF, EXE"
            attached={attached}
            onSelectFile={onSelectFile}
            folderSelected={folderSelected}
            setFolderSelected={setFolderSelected}
            onDeleteFile={onDeleteFile}
            errorMessages={errorMessages}
            showIcons={{ download: false }}
          />
        </ContainerCard>
      </Form>
    </>
  );
};

export default ThirdStepRequestTemplate;
