import React, { createContext, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { addTokenCognito, provider, signOutUrl } from './constant';
import { changeLoading, loginFedereation } from '../../redux/ducks/user';

export const AwsContext = createContext();
export const AwsContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const oauthGoogle = async () => {
    try {
      dispatch(changeLoading(true));
      await Auth.federatedSignIn({ provider: provider.google });
    } catch (event) {
      dispatch(changeLoading(false));
      alert('Error en la autenticación');
    }
  };

  const logoutFederation = async () => {
    try {
      await Auth.signOut();

      window.location.href = signOutUrl;
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

  useEffect(() => {
    try {
      const saveTokenToLocalStorage = () => {
        const idToken = addTokenCognito();
        if (idToken) dispatch(loginFedereation('anthony.ricse98@gmail.com'));
        else dispatch(changeLoading(false));
      };
      saveTokenToLocalStorage();
    } catch (e) {
      alert('Error en la autenticación');
    }
  }, []);

  return (
    <AwsContext.Provider value={{ oauthGoogle, logoutFederation }}>{children}</AwsContext.Provider>
  );
};

export const useAws = () => {
  return useContext(AwsContext);
};
