import React from 'react';
import { Col, Row, Typography, Form } from 'antd';
import { isEmpty } from 'lodash';

import { businessTypes, companyProperty, validationType } from '../../utilities/Constant';
import { requestProperty } from '../../templates/NewRequest/constants';
import { getValueDefault, validateEmail } from '../../utilities/Functions';

import PTextInput from '../PTextInput/index';
import WSelectInput from '../SelectInput/index';
import ContainerCard from '../ContainerCard/ContainerCard';
import CustomIcon from '../CustomIcon/CustomIcon';
import NaturalRepresentativeCard from '../NaturalRepresentativeCard';

import './index.less';

const { Title, Text } = Typography;
// TODO example data, change to real data

const WLegalRepresentativeCard = ({
  personTypeIndex,
  onFormStateChange,
  counterPart,
  currentCompany,
  selectedIndexCompany,
  representatives,
  selectedRepresentative,
  onSelectRepresentative,
  addRepresentative,
  selectedIndexRepresentative,
  currentLegalRepresentative,
  onDeleteRepresentative = () => {},
  errorMessages,
}) => {
  const onChangeValue = (value, property) => {
    const counterPartToUpdate = [...counterPart];
    counterPartToUpdate[personTypeIndex].companies[selectedIndexCompany][property] = value;
    if (isEmpty(value)) {
      delete counterPartToUpdate[personTypeIndex].companies[selectedIndexCompany][property];
    }
    onFormStateChange(requestProperty.COUNTER_PART, counterPartToUpdate);
  };

  const handleDeleteRepresentative = () => {
    onDeleteRepresentative(selectedRepresentative);
  };

  const checkEmail = () => {
    const currentEmail = currentCompany[companyProperty.EMAIL];
    return validateEmail(currentEmail);
  };

  return (
    <ContainerCard className="firstRepresentativeCard-container">
      {personTypeIndex === 0 && (
        <div className="firstRepresentative-container">
          <div className="firstRepresentative-titleContainer">
            <Title className="firstRepresentative-title">Información de la empresa</Title>
          </div>
          <Text className="firstRepresentative-subtitleContainer">
            <CustomIcon className="firstRepresentative-subtitleIcon" type="ic_alert_withoutBackg" />
            Es obligatorio completar todo los campos.
          </Text>
          <Row className="firstRepresentativeCard-row">
            <Col flex="1 1 180px">
              <Form.Item
                name={`${companyProperty.NAME}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese nombre de la empresa' }]}
              >
                <PTextInput
                  placeholder="Nombre de la empresa"
                  value={getValueDefault(currentCompany[companyProperty.NAME])}
                  onChange={(value) => onChangeValue(value, companyProperty.NAME)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 1 180px">
              <Form.Item
                name={`${companyProperty.RUC}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese número de RUC' }]}
              >
                <PTextInput
                  placeholder="Número de Ruc"
                  value={getValueDefault(currentCompany[companyProperty.RUC])}
                  onChange={(value) => onChangeValue(value, companyProperty.RUC)}
                  type="number"
                  maxLength={11}
                  validation={validationType.RUC_LEGAL}
                  errorMessage={`${
                    errorMessages && getValueDefault(currentCompany[companyProperty.RUC])
                      ? 'RUC jurídico inválido'
                      : ''
                  }`}
                />
              </Form.Item>
            </Col>
            <Col flex="1 1 180px">
              <Form.Item
                name={`${companyProperty.SOCIAL_REASON}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese razón social' }]}
              >
                <PTextInput
                  placeholder="Razón social"
                  value={getValueDefault(currentCompany[companyProperty.SOCIAL_REASON])}
                  onChange={(value) => onChangeValue(value, companyProperty.SOCIAL_REASON)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 0 180px">
              <Form.Item
                name={`${companyProperty.TYPE}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Seleccione tipo de empresa' }]}
              >
                <WSelectInput
                  options={businessTypes}
                  placeholder="Tipo de empresa"
                  value={currentCompany[companyProperty.TYPE]}
                  onChange={(value) => onChangeValue(value, companyProperty.TYPE)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 0 180px">
              <Form.Item
                name={`${companyProperty.ELECTRONIC_CERTIFICATE}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese partida Electrónica' }]}
              >
                <PTextInput
                  placeholder="Partida Electrónica"
                  value={getValueDefault(currentCompany[companyProperty.ELECTRONIC_CERTIFICATE])}
                  onChange={(value) => onChangeValue(value, companyProperty.ELECTRONIC_CERTIFICATE)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 0 210px">
              <Form.Item
                name={`${companyProperty.ADDRESS}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese domicilio Fiscal' }]}
              >
                <PTextInput
                  placeholder="Domicilio Fiscal"
                  value={getValueDefault(currentCompany[companyProperty.ADDRESS])}
                  onChange={(value) => onChangeValue(value, companyProperty.ADDRESS)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 0 210px">
              <Form.Item
                name={`${companyProperty.REGISTER_OFFICE}${selectedIndexCompany}`}
                rules={[{ required: true, message: 'Ingrese sede de la Oficina Registral' }]}
              >
                <PTextInput
                  placeholder="Sede de la Oficina Registral"
                  value={getValueDefault(currentCompany[companyProperty.REGISTER_OFFICE])}
                  onChange={(value) => onChangeValue(value, companyProperty.REGISTER_OFFICE)}
                />
              </Form.Item>
            </Col>
            <Col flex="1 0 210px">
              <Form.Item
                name={`${companyProperty.EMAIL}${selectedIndexCompany}`}
                rules={[{ required: true, validator: checkEmail }]}
              >
                <PTextInput
                  placeholder="Correo de contacto"
                  value={getValueDefault(currentCompany[companyProperty.EMAIL])}
                  onChange={(value) => onChangeValue(value, companyProperty.EMAIL)}
                  lowerCase
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
      <NaturalRepresentativeCard
        personTypeIndex={personTypeIndex}
        onFormStateChange={onFormStateChange}
        counterPart={counterPart}
        selectedIndexCompany={selectedIndexCompany}
        representatives={representatives}
        selectedRepresentative={selectedRepresentative}
        onSelectRepresentative={onSelectRepresentative}
        addRepresentative={addRepresentative}
        selectedIndexRepresentative={selectedIndexRepresentative}
        currentRepresentative={currentLegalRepresentative}
        deleteRepresentative={handleDeleteRepresentative}
        errorMessages={errorMessages}
      />
    </ContainerCard>
  );
};

export default WLegalRepresentativeCard;
